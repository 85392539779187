//
//
//
//
//
//

export default {
  name: 'CodeBlock',
  props: {
    label: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
}
