// const STORAGE_KEY = 'todos-vuejs'

export default () => ({
  // todos: JSON.parse(window.localStorage.getItem(STORAGE_KEY) || '[]'),
  todos: [],
  newTodo: '',
  list: [
    {id: 1, text: 'Show work', done: false},
    {id: 2, text: 'Use nuxt', done: true},
  ],
})
