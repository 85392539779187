const todos = state => state.list
const doneTodos = state => state.list.filter(todo => todo.done)
const doneTodosCount = (state, getters) => getters.doneTodos.length
const getTodoById = state => id => state.list.find(todo => todo.id === id)

export default {
  todos,
  doneTodos,
  doneTodosCount,
  getTodoById,
}
