import {OnDemand} from '../helpers/on-demand'

export default (ctx, inject) => {
  const loader = new OnDemand(
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyBKANcqkf40AUjz5IadW_H5wzijw1ZRkmY&libraries=places',
  )

  inject('places', {
    plot(el, lat, lng) {
      loader.load(() => {
        // eslint-disable-next-line no-new
        new window.google.maps.Map(el, {
          center: {lat, lng},
          zoom: 17,
        })
      })
    },
  })
}
