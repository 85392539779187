import Vue from 'vue'
import Chakra from '@chakra-ui/vue'
import customTheme from '@/utils/custom-theme'
import customIcons from '@/utils/custom-icons'

Vue.use(Chakra, {
  extendTheme: customTheme,
  icons: {
    extend: {
      ...customIcons,
    },
  },
})
