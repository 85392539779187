const ADD_EVENT = (state, event) => {
  state.events.push(event)
}
const SET_EVENTS = (state, events) => {
  state.events = events
}
const SET_EVENTS_TOTAL = (state, eventsTotal) => {
  state.eventsTotal = eventsTotal
}
const SET_EVENT = (state, event) => {
  state.event = event
}
const EDIT_EVENT = (state, event) => {
  const events = state.events
  events.splice(events.indexOf(event), 1)
  state.events = events
  state.newEvent = {
    title: event.title,
    date: event.date,
    time: event.time,
    location: event.location,
    description: event.description,
    organizer: event.organizer,
    category: event.category,
  }
}

const REMOVE_EVENT = (state, event) => {
  const events = state.events
  events.splice(events.indexOf(event), 1)
}

export default {
  ADD_EVENT,
  SET_EVENTS,
  SET_EVENTS_TOTAL,
  SET_EVENT,
  EDIT_EVENT,
  REMOVE_EVENT,
}
