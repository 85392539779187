import { render, staticRenderFns } from "./ColorSwitcher.vue?vue&type=template&id=246c3686&"
import script from "./ColorSwitcher.vue?vue&type=script&lang=js&"
export * from "./ColorSwitcher.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconSun: require('/opt/build/repo/components/icons/IconSun.vue').default,IconMoon: require('/opt/build/repo/components/icons/IconMoon.vue').default,Button: require('/opt/build/repo/components/Button.vue').default})
