// const themes = [
//   'blue_theme',
//   'dark_gray_theme',
//   'dark_green_theme',
//   'dark_red_theme',
//   'default_theme',
//   'green_theme',
//   'light_blue_theme',
//   'light_gray_theme',
//   'light_green_theme',
//   'light_orange_theme',
//   'purple_theme',
//   'red_theme',
//   'red_orange_theme',
// ]

// grays.js
// export default {
//   tailwind: {
//     200: '#edf2f7',
//     300: '#e2e8f0',
//     100: '#f7fafc',
//     400: '#cbd5e0',
//     500: '#a0aec0',
//     600: '#718096',
//     700: '#4a5568',
//     800: '#2d3748',
//     900: '#1a202c',
//   },
//   material: {
//     50: '#fafafa',
//     100: '#f5f5f5',
//     200: '#eeeeee',
//     300: '#e0e0e0',
//     400: '#bdbdbd',
//     500: '#9e9e9e',
//     600: '#757575',
//     700: '#616161',
//     800: '#424242',
//     900: '#212121',
//   },
//   cloudflare: {
//     50: '#f7f7f8',
//     100: '#eaebeb',
//     200: '#d5d7d8',
//     300: '#b7bbbd',
//     400: '#92979b',
//     500: '#72777b',
//     600: '#62676a',
//     700: '#4e5255',
//     800: '#36393a',
//     900: '#1d1f20',
//   },
//   carbon: {
//     50: '#f3f3f3',
//     100: '#dcdcdc',
//     200: '#bebebe',
//     300: '#a4a4a4',
//     400: '#8c8c8c',
//     500: '#6f6f6f',
//     600: '#565656',
//     700: '#3d3d3d',
//     800: '#282828',
//     900: '#171717',
//   },
// }

export default {
  breakpoints: ['30em', '48em', '62em', '80em'],
  fonts: {
    heading: '"Source Sans Pro", "Inter var", "Avenir Next", sans-serif',
    body: '"Source Sans Pro", "Inter var", system-ui, sans-serif',
    mono: 'Menlo, monospace',
  },
  colors: {
    javascript: '#F7DF1E',
    brand: {
      50: '#daffff',
      100: '#b1fbfb',
      200: '#85f7f7',
      300: '#58f3f3',
      400: '#31f0f0',
      500: '#1ed7d7',
      600: '#0ca7a7',
      700: '#007777',
      800: '#004949',
      900: '#001a1a',
    },
  },
}
