const SET_TODOS = (state, list) => {
  state.list = list
}

const GET_TODO = (state, todo) => {
  state.newTodo = todo
}

const TOGGLE = (state, todo) => {
  todo.done = !todo.done
}

const ADD_TODO = (state, todo) => {
  state.todos.push(todo)
}

const ADD = (state, {text}) => {
  state.list.push({
    text,
    done: false,
  })
}
// const ADD_TODO = (state) => {
//   state.todos.push({
//     body: state.newTodo,
//     completed: false
//   })
// }
const REMOVE_TODO = (state, todo) =>
  state.todos.splice(state.todos.indexOf(todo), 1)
const REMOVE = (state, {todo}) => state.list.splice(state.list.indexOf(todo), 1)
// const REMOVE_TODO = (state, todo) => {
//   var todos = state.todos
//   todos.splice(todos.indexOf(todo), 1)
// }
const EDIT_TODO = (state, {todo, text = todo.text, done = todo.done}) => {
  todo.text = text
  todo.done = done
}
// const EDIT_TODO = (state, todo) => {
//   var todos = state.todos
//   todos.splice(todos.indexOf(todo), 1)
//   state.todos = todos
//   state.newTodo = todo.body
// }
const COMPLETE_TODO = (state, todo) => {
  todo.completed = !todo.completed
}
const CLEAR_TODO = state => {
  state.newTodo = ''
}

export default {
  ADD,
  SET_TODOS,
  GET_TODO,
  REMOVE,
  TOGGLE,
  ADD_TODO,
  REMOVE_TODO,
  EDIT_TODO,
  COMPLETE_TODO,
  CLEAR_TODO,
}
