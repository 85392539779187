//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CodeSandbox',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isIntersecting: false,
    }
  },
  mounted() {
    if (!window.IntersectionObserver) {
      this.isIntersecting = true
      return
    }

    this.__observer = new window.IntersectionObserver(entries => {
      entries.forEach(({intersectionRatio, target: el}) => {
        if (intersectionRatio > 0) {
          this.isIntersecting = true
          this.__observer.disconnect()
          delete this.__observer
        }
      })
    })
    this.__observer.observe(this.$el)
  },
  beforeDestroy() {
    if (this.__observer) {
      this.__observer.disconnect()
      delete this.__observer
    }
  },
}
