import { render, staticRenderFns } from "./docs.vue?vue&type=template&id=e3fa889c&"
import script from "./docs.vue?vue&type=script&lang=js&"
export * from "./docs.vue?vue&type=script&lang=js&"
import style0 from "./docs.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navbar: require('/opt/build/repo/components/Navbar.vue').default,SearchInput: require('/opt/build/repo/components/SearchInput.vue').default,IconTwitter: require('/opt/build/repo/components/icons/IconTwitter.vue').default,IconGithub: require('/opt/build/repo/components/icons/IconGithub.vue').default,LangSwitcher: require('/opt/build/repo/components/core/LangSwitcher.vue').default,ColorSwitcher: require('/opt/build/repo/components/core/ColorSwitcher.vue').default,TheFooter: require('/opt/build/repo/components/TheFooter.vue').default})
