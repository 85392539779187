export const add = ({commit}, notification) => {
  commit('PUSH', notification)
}

export const remove = ({commit}, notificationToRemove) => {
  commit('DELETE', notificationToRemove)
}

export default {
  add,
  remove,
}
