//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClickAway from '@/directives/click-away'

export default {
  name: 'Dropdown',
  directives: {
    'click-away': ClickAway,
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    toggle() {
      this.open = !this.open
    },
    close() {
      this.open = false
    },
  },
}
