//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ColorSwitcher',
}
