import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=5d483d69&"
import script from "./Navbar.vue?vue&type=script&lang=js&"
export * from "./Navbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconLogo: require('/opt/build/repo/components/icons/IconLogo.vue').default,IconLogoDark: require('/opt/build/repo/components/icons/IconLogoDark.vue').default,SearchInput: require('/opt/build/repo/components/SearchInput.vue').default,IconGithub: require('/opt/build/repo/components/icons/IconGithub.vue').default,IconX: require('/opt/build/repo/components/icons/IconX.vue').default,IconMenu: require('/opt/build/repo/components/icons/IconMenu.vue').default,Button: require('/opt/build/repo/components/Button.vue').default})
