let nextId = 1

export const PUSH = (state, notification) => {
  state.notifications.push({
    ...notification,
    id: nextId++,
  })
}

export const DELETE = (state, notificationToRemove) => {
  state.notifications = state.notifications.filter(
    notification => notification.id !== notificationToRemove.id,
  )
}

export default {
  PUSH,
  DELETE,
}
