import Vue from 'vue'
// import createPersistedState from 'vuex-persistedstate'
import groupBy from 'lodash.groupby'
import Api from '@/services/api'

export const state = () => ({
  isLoaded: false,
  authenticated: false,
  categories: {},
  classrooms: [],
  count: 4,
  currentUser: {},
  locale: 'en',
  locales: ['en', 'es'],
  notifications: [],
  playedVideos: [],
  tags: [],
  user: {},
  users: [],
  videos: [],
  page: 'index',
})

export const actions = {
  increment: ({commit}) => {
    commit('INCREMENT')
  },

  async fetchCategories({commit, state}) {
    // Avoid re-fetching in production
    if (process.dev === false && state.categories[this.$i18n.locale]) {
      return
    }
    const docs = await this.$content(this.$i18n.locale)
      .only(['category', 'title', 'slug'])
      .sortBy('position', 'asc')
      .fetch()
    const categories = groupBy(docs, 'category')

    commit('SET_CATEGORIES', categories)
  },

  // nuxtServerInit({commit}, {req}) {
  //   if (req.session.user) {
  //     commit('SET_USER', req.session.user)
  //   }
  // },

  // nuxtServerInit({commit}) {
  //   commit('SET_LOCALE', this.$i18n.locale)
  // },

  // async nuxtServerInit({ commit }, { $sentry }) {
  //   try {
  //     let { data } = await axios.get(`https://my-api/timestamp`)
  //     commit('setTimeStamp', data)
  //   } catch (error) {
  //     $sentry.captureException(error)
  //   }
  // }

  async loginUser({commit}, loginInfo) {
    try {
      const response = await Api().post('/sessions', loginInfo)
      const user = response.data.data.attributes

      commit('SET_CURRENT_USER', user)
      return user
    } catch {
      return {
        error: 'Email/password combination was incorrect.  Please try again.',
      }
    }
  },

  logoutUser({commit}) {
    commit('LOGOUT_USER')
  },

  async registerUser({commit}, registrationInfo) {
    try {
      const response = await Api().post('/users', registrationInfo)
      const user = response.data.data.attributes

      commit('SET_CURRENT_USER', user)
      return user
    } catch {
      return {error: 'There was an error.  Please try again.'}
    }
  },

  async loadUsers({commit}) {
    const response = await Api().get('/users')
    const users = response.data.data
    commit(
      'SET_USERS',
      users.map(u => u.attributes),
    )

    const user = JSON.parse(window.localStorage.currentUser)
    commit('SET_CURRENT_USER', user)
  },

  setUser: ({state, commit, getters, dispatch}, user) => {
    // Restructure the user for the dashboard page
    const participants = user.participants
    delete user.participants
    const programs = []
    const classrooms = []
    participants.forEach(participant => {
      const programId = participant.participant_info.classroom.group.program.id
      classrooms.push(participant.participant_info.classroom)
      participant.participant_info.pledges.forEach(pledge => {
        pledge.amount = parseFloat(pledge.amount)
      })
      const family_pledging_enabled =
        participant.participant_info.family_pledging_enabled &&
        participant.participant_info.classroom.group.program
          .program_pledge_setting.family_pledging_enabled
      let program = programs.find(program => program.id === programId)
      if (program === undefined || !family_pledging_enabled) {
        program = participant.participant_info.classroom.group.program
        program.participants = []
        programs.push(program)
      }
      program.participants.push(participant)
    })

    // Sort Alphabetically
    programs.forEach(program => {
      program.participants.sort((a, b) =>
        ('' + a.first_name).localeCompare(b.first_name),
      )
    })
    programs.sort((a, b) => ('' + a.name).localeCompare(b.name))

    user.programs = programs

    if (typeof user.notifications !== 'undefined') {
      dispatch('setNotifications', user.notifications)
    }

    dispatch('setClassrooms', classrooms)
    commit('SET_USER', user)
  },
  setNotifications({commit}, notifications) {
    commit('SET_NOTIFICATIONS', notifications)
  },
  setClassrooms: ({commit}, classrooms) => {
    commit('SET_CLASSROOMS', classrooms)
  },
}

export const mutations = {
  INCREMENT(state) {
    state.count++
  },
  SET_LANG(state, locale) {
    if (state.locales.includes(locale)) {
      state.locale = locale
    }
  },
  SET_USER(state, user) {
    state.user = user
  },
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications
  },
  SET_CLASSROOMS(state, classrooms) {
    state.classrooms = classrooms
  },

  SET_USERS(state, users) {
    state.users = users
  },

  LOGOUT_USER(state) {
    state.currentUser = {}
    window.localStorage.currentUser = JSON.stringify({})
  },

  SET_CURRENT_USER(state, user) {
    state.currentUser = user
    window.localStorage.currentUser = JSON.stringify(user)
  },

  FINISH_LOADING(state) {
    state.isLoaded = true
  },

  UPDATE_PAGE(state, pageName) {
    state.page = pageName
  },

  SET_CATEGORIES(state, categories) {
    // Vue Reactivity rules since we add a nested object
    Vue.set(state.categories, this.$i18n.locale, categories)
  },
}

// export const plugins = [createPersistedState()]
