const middleware = {}

middleware['auth-admin'] = require('../middleware/auth-admin.js')
middleware['auth-admin'] = middleware['auth-admin'].default || middleware['auth-admin']

middleware['auth-guard'] = require('../middleware/auth-guard.js')
middleware['auth-guard'] = middleware['auth-guard'].default || middleware['auth-guard']

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['i18n'] = require('../middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['pages'] = require('../middleware/pages.js')
middleware['pages'] = middleware['pages'].default || middleware['pages']

export default middleware
