import Vue from 'vue'

import {
  CBox,
  CButton,
  CFlex,
  CFormControl,
  CFormLabel,
  CHeading,
  CIconButton,
  CInput,
  CInputGroup,
  CInputRightElement,
  CLink,
  CSelect,
  CText,
} from '@chakra-ui/vue'

// PROJECT: COMMONS
// import development from '@/config/development.json'
// import production from '@/config/production.json'

// if (process.env.NODE_ENV === 'production') {
//   Vue.prototype.$config = Object.freeze(production)
// } else {
//   Vue.prototype.$config = Object.freeze(development)
// }

Vue.prototype.$appEyebrow = '@Boosterthon/frontier'
Vue.prototype.$appName = 'Proof of Concept'
Vue.prototype.$appTagline = 'Resilient UI Component Patterns for Vue projects'

Vue.component('CBox', CBox)
Vue.component('CButton', CButton)
Vue.component('CFormControl', CFormControl)
Vue.component('CFormLabel', CFormLabel)
Vue.component('CFlex', CFlex)
Vue.component('CHeading', CHeading)
Vue.component('CIconButton', CIconButton)
Vue.component('CInput', CInput)
Vue.component('CInputGroup', CInputGroup)
Vue.component('CInputRightElement', CInputRightElement)
Vue.component('CLink', CLink)
Vue.component('CSelect', CSelect)
Vue.component('CText', CText)
