const toggleAll = ({commit}, payload) => {
  commit('TOGGLE_ALL', payload)
}

const removeMessage = ({commit, state}, message) => {
  commit('REMOVE_MESSAGE', message.id)
}

const removeMessages = ({commit}) => {
  commit('REMOVE_MESSAGES')
}

const fetchMessages = async ({app, store, error, isClient}) => {
  if (isClient) {
    return
  }

  try {
    const messages = await app.$axios.$get(
      `${process.env.baseURL}/api/messages`,
    )
    store.commit('SET_MESSAGES', messages)
  } catch (err) {
    store.commit('SET_MESSAGES', [])
    error({statusCode: 500, message: 'Oops, try again'})
  }
}

export default {
  toggleAll,
  removeMessage,
  removeMessages,
  fetchMessages,
}
