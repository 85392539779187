//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MovieInfo',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
}
