export default {
  'alert-octagon': {
    path: `<path fill="currentColor" d="M13,13H11V7H13M12,17.3A1.3,1.3 0 0,1 10.7,16A1.3,1.3 0 0,1 12,14.7A1.3,1.3 0 0,1 13.3,16A1.3,1.3 0 0,1 12,17.3M15.73,3H8.27L3,8.27V15.73L8.27,21H15.73L21,15.73V8.27L15.73,3Z"/>`,
    // If the icon's viewBox is `0 0 24 24`, you can ignore `viewBox`
    viewBox: '0 0 40 40',
  },
  menu: {
    path: `<g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></g>`,
  },
  close: {
    path: `<g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></g>`,
  },
  copyLink: {
    path: `<path fill="currentColor" d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z" class=""/>`,
    viewBox: '0 0 1095 1095',
  },
  cellphone: {
    path: `<g fill="none" fill-rule="evenodd"><circle cx="547.5" cy="547.5" r="547.5" fill="currentColor" class="text-gray-200"></circle> <path d="M659 291H435c-26.5 0-48 21.5-48 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V339c0-26.5-21.5-48-48-48zM547 771c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H447c-6.6 0-12-5.4-12-12V351c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z" fill="currentColor" fill-rule="nonzero"></path></g>`,
    viewBox: '0 0 1095 1095',
  },
  cube: {
    path: `<g fill="none"><path class="text-primary-300" d="M2 10L20 0l18 10v20L20 40 2 30V10z" fill="currentColor" /><path class="text-primary-600" d="M2 10v20l18 10V20L2 10z" fill="currentColor" /><path class="text-primary-800" d="M38 30V10L20 20v20l18-10z" fill="currentColor" /></g>`,
    viewBox: '0 0 40 40',
  },
  email: {
    path: `<g fill="none" fill-rule="evenodd"><circle cx="547.5" cy="547.5" r="547.5" fill="currentColor" class="text-gray-200"></circle> <path d="M799.3 490.8c3.9-3.1 9.7-.2 9.7 4.7V700c0 26.5-21.5 48-48 48H345c-26.5 0-48-21.5-48-48V495.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM553 620c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H345c-26.5 0-48 21.5-48 48v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" fill="currentColor" fill-rule="nonzero"></path></g>`,
    viewBox: '0 0 1095 1095',
  },
  facebook: {
    path: `<g fill="none" fill-rule="evenodd"><circle cx="547.5" cy="547.5" r="547.5" fill="currentColor" class="text-gray-200"></circle> <path d="M483.7 796V567H407v-91h76.7v-71.7c0-77.9 47.6-120.3 117.1-120.3 33.3 0 61.9 2.5 70.2 3.6V369h-48.2c-37.8 0-45.1 18-45.1 44.3V476H663l-11.7 91h-73.6v229" fill="currentColor" fill-rule="nonzero"></path></g>`,
    viewBox: '0 0 1095 1095',
  },
  heart: {
    path: `<g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></g>`,
  },
  sms: {
    path: `<g fill="none" fill-rule="evenodd"><circle cx="547.5" cy="547.5" r="547.5" fill="currentColor" class="text-gray-200"></circle> <path d="M548 324C406.6 324 292 417.1 292 532C292 581.6 313.4 627 349 662.7C336.5 713.1 294.7 758 294.2 758.5C292 760.8 291.4 764.2 292.7 767.2C294 770.2 296.8 772 300 772C366.3 772 416 740.2 440.6 720.6C473.3 732.9 509.6 740 548 740C689.4 740 804 646.9 804 532C804 417.1 689.4 324 548 324Z" fill="currentColor" fill-rule="nonzero"></path></g>`,
    viewBox: '0 0 1095 1095',
  },
  share: {
    viewBox: '0 0 1095 1095',
    path: `<g fill="none" fill-rule="evenodd"><circle cx="547.5" cy="547.5" r="547.5" fill="currentColor" class="text-gray-200"></circle> <path d="M882.482 449.448L738.479 585.433C723.3 599.768 698 589.14 698 567.985v-71.963c-144.575.97-205.566 35.113-164.775 171.353 4.483 14.973-12.846 26.567-25.006 17.33C469.252 655.105 434 598.488 434 541.339c0-143.937 117.599-172.5 264-173.312v-72.015c0-21.174 25.317-31.768 40.479-17.448l144.003 135.988c10.02 9.463 10.028 25.425 0 34.896zM698 651.128V720H378V400h50.916a11.99 11.99 0 008.648-3.693c14.953-15.568 32.237-27.89 51.014-37.676 11.13-5.801 7.006-22.631-5.545-22.631H362c-26.51 0-48 21.49-48 48v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48v-88.806c0-8.288-8.197-14.066-16.011-11.302a71.83 71.83 0 01-34.189 3.377c-7.27-1.046-13.8 4.514-13.8 11.859z" fill="currentColor" fill-rule="nonzero"></path></g>`,
  },
  angular: {
    viewBox: '0 0 16 16',
    path: `
      <g>
        <path d="M1.53,3.62,7.92,1.34l6.56,2.24L13.42,12l-5.5,3-5.41-3Z" fill="#e23237"/>
        <path d="M14.48,3.58,7.92,1.34V15.08l5.5-3,1.06-8.46Z" fill="#b52e31"/>
        <path d="M7.93,2.95l-4,8.86,1.49,0,.8-2H9.8l.88,2,1.42,0L7.93,2.95Zm0,2.84L9.28,8.6H6.75L7.94,5.79Z" fill="#fff"/>
      </g>
    `,
  },
}
