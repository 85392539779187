import EventService from '@/services/EventService.js'

const createEvent = ({commit, dispatch}, event) => {
  return EventService.postEvent(event)
    .then(() => {
      commit('ADD_EVENT', event)
      const notification = {
        type: 'success',
        message: 'Your event has been created!',
      }
      dispatch('notifications/add', notification, {root: true})
    })
    .catch(error => {
      const notification = {
        type: 'error',
        message: 'There was a problem creating your event: ' + error.message,
      }
      dispatch('notifications/add', notification, {root: true})
      throw error
    })
}

const fetchEvents = ({commit, dispatch}, {perPage, page}) => {
  EventService.getEvents(perPage, page)
    .then(response => {
      commit('SET_EVENTS_TOTAL', parseInt(response.headers['x-total-count']))
      commit('SET_EVENTS', response.data)
    })
    .catch(error => {
      const notification = {
        type: 'error',
        message: 'There was a problem fetching events: ' + error.message,
      }
      dispatch('notifications/add', notification, {root: true})
    })
}

const fetchEvent = ({commit, getters, dispatch}, id) => {
  const event = getters.getEventById(id)

  if (event) {
    commit('SET_EVENT', event)
  } else {
    EventService.getEvent(id)
      .then(response => {
        commit('SET_EVENT', response.data)
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching event: ' + error.message,
        }
        dispatch('notifications/add', notification, {root: true})
      })
  }
}

const editEvent = ({commit, getters, dispatch}, id) => {
  const event = getters.getEventById(id)

  if (event) {
    commit('EDIT_EVENT', event)
  } else {
    EventService.getEvent(id)
      .then(response => {
        commit('EDIT_EVENT', response.data)
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching event: ' + error.message,
        }
        dispatch('notifications/add', notification, {root: true})
      })
  }
}

const removeEvent = ({commit}, event) => {
  commit('REMOVE_EVENT', event)
}

export default {
  createEvent,
  fetchEvents,
  fetchEvent,
  editEvent,
  removeEvent,
}
