import { render, staticRenderFns } from "./LangSwitcher.vue?vue&type=template&id=9a430ee0&"
import script from "./LangSwitcher.vue?vue&type=script&lang=js&"
export * from "./LangSwitcher.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconTranslate: require('/opt/build/repo/components/icons/IconTranslate.vue').default,Button: require('/opt/build/repo/components/Button.vue').default,Dropdown: require('/opt/build/repo/components/core/Dropdown.vue').default})
