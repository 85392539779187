//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CodeGroup',
  data() {
    return {
      tabs: [],
      activeTabIndex: 0,
    }
  },
  watch: {
    activeTabIndex(newValue, oldValue) {
      this.switchTab(newValue)
    },
  },
  created() {
    this.$slots.default
      .filter(slot => Boolean(slot.componentOptions))
      .map(slot => {
        this.tabs.push({
          label: slot.componentOptions.propsData.label,
          elm: null,
        })
      })
    this.activeTabIndex = 0
  },
  mounted() {
    this.tabs = this.$slots.default
      .filter(slot => Boolean(slot.componentOptions))
      .map(slot => {
        return {
          label: slot.componentOptions.propsData.label,
          elm: slot.elm,
        }
      })
    this.updateHighlighteUnderlinePosition()
  },
  methods: {
    switchTab(i) {
      this.tabs.map(tab => {
        tab.elm.classList.remove('active')
      })
      this.tabs[i].elm.classList.add('active')
    },
    updateTabs(i) {
      this.activeTabIndex = i
      this.updateHighlighteUnderlinePosition()
    },
    updateHighlighteUnderlinePosition() {
      const activeTab = this.$refs.tabs[this.activeTabIndex]
      if (!activeTab) {
        return
      }
      const highlightUnderline = this.$refs['highlight-underline']
      highlightUnderline.style.left = `${activeTab.offsetLeft}px`
      highlightUnderline.style.width = `${activeTab.clientWidth}px`
    },
  },
}
